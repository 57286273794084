<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M35.5116 13.4884C29.527 7.50386 19.7879 7.50386 13.8034 13.4884L10.5584 16.7312V12.1453C10.5584 11.4396 9.98491 10.866 9.27921 10.866C8.57351 10.866 8 11.4396 8 12.1453V19.8205C8 19.9015 8.00853 19.9804 8.02345 20.0572C8.02345 20.0614 8.02345 20.0636 8.02558 20.0678C8.04051 20.1446 8.06396 20.2192 8.09168 20.2917C8.09381 20.296 8.09594 20.3024 8.09807 20.3066C8.12792 20.3791 8.1663 20.4495 8.20894 20.5156C8.21107 20.5198 8.2132 20.5241 8.21747 20.5284C8.31127 20.6712 8.4328 20.7927 8.57564 20.8865C8.57991 20.8887 8.58417 20.8908 8.58844 20.8951C8.65453 20.9377 8.72275 20.9739 8.79737 21.0038C8.80164 21.0059 8.80803 21.0081 8.8123 21.0102C8.88479 21.0379 8.95728 21.0614 9.03616 21.0763C9.04042 21.0763 9.04469 21.0763 9.04682 21.0784C9.12357 21.0933 9.20246 21.1019 9.28347 21.1019H16.9587C17.6644 21.1019 18.238 20.5284 18.238 19.8227C18.238 19.117 17.6644 18.5434 16.9587 18.5434H12.3685L15.6113 15.3006C18.029 12.8808 21.2398 11.5504 24.6575 11.5504C28.0751 11.5504 31.2859 12.8808 33.7036 15.2964C38.6904 20.2832 38.6904 28.3998 33.7036 33.3866C31.2859 35.8043 28.0751 37.1346 24.6575 37.1346C21.2398 37.1346 18.029 35.8043 15.6113 33.3887C15.1124 32.8898 14.3022 32.8898 13.8012 33.3887C13.3023 33.8876 13.3023 34.6978 13.8012 35.1988C16.7029 38.0962 20.5576 39.6931 24.6575 39.6931C28.7573 39.6931 32.612 38.0962 35.5116 35.1966C41.4961 29.2121 41.4961 19.473 35.5116 13.4884Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-rotate-left-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
